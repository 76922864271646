
<script setup>
import { ref } from "vue";
import { useForm, usePage } from '@inertiajs/vue3'
import PasswordToggle from "@/Components/Forms/PasswordToggle.vue";

const form = useForm({
    email: null,
    password: null,
    remember: false,
})

const { props } = usePage()

function login() {
    form.post('/authenticate', form)
}

const passwordVisibility = ref(false);
const togglePasswordVisibility = () => {
    passwordVisibility.value = !passwordVisibility.value;
};

</script>
<template>
    <div>
        <div class="relative flex flex-col min-h-screen">
            <div class="container my-auto px-9 md:pt-14 md:px-10 md:pb-[72px] max-w-[485px] md:dark:bg-gray-900 flex flex-col text-black dark:text-white sm:rounded-lg relative z-10 md:shadow-2xl">

                <a href="/" class="inline-block mb-9 -ms-1"><svg-icon name="arrow-left"></svg-icon></a>

                <h2 class="text-3xl font-medium mb-6">Log in to Yoclo.</h2>

                <!-- Display success message if available -->
                <div v-if="props.flash.success" class="text-green-500">
                    {{ props.flash.success }}
                </div>

                <!-- Display general error message -->
                <div v-if="form.errors.error" class="text-red-500 text-sm mt-1 mb-3">
                    {{ form.errors.error }}
                </div>

                <form @submit.prevent="login">
                    <div class="mb-6">
                        <input-label for="email" value="Email" class="font-normal" />
                        <input-text
                            v-model="form.email"
                            id="email"
                            type="email"
                            name="email"
                            required
                            autofocus
                            autocomplete="username"
                            placeholder="Enter in your email"
                        />
                        <!-- Error for Email -->
                        <div v-if="form.errors.email" class="text-red-500 text-sm mt-1">
                            {{ form.errors.email }}
                        </div>
                    </div>

                    <!-- Password -->
                    <div class="mt-8 mb-4">
                        <input-label for="password" value="Password" class="font-normal" />

                        <div class="relative">
                            <input-text
                                v-model="form.password"
                                id="password"
                                :type="passwordVisibility ? 'text' : 'password'"
                                name="password"
                                autofocus
                                required
                                autocomplete="current-password"
                                placeholder="Enter in your password"
                            />
                            <PasswordToggle :is-password-visible="passwordVisibility" :toggleFunction="togglePasswordVisibility" />
                        </div>
                        <!-- Error for Password -->
                        <div v-if="form.errors.password" class="text-red-500 text-sm mt-1">
                            {{ form.errors.password }}
                        </div>
                    </div>

                    <!-- Remember Me -->
                    <div class="flex justify-between items-center mb-12">
                        <div class="flex items-center mt-6">
                            <input-checkbox
                                v-model="form.remember"
                                id="remember"
                                name="remember"
                                class="me-4 appearance-none border border-gray-500 dark:border-[#F7F7F7] rounded-[6px] h-7 w-7 shadow-sm focus-visible:ring-indigo-500 dark:focus-visible:ring-indigo-600 dark:focus-visible:ring-offset-gray-800"
                            />
                            <input-label
                                for="remember"
                                class="max-w-60"
                                value="Remember Me"
                            />
                        </div>

                        <a
                            href="/forgot-password"
                            class="underline underline-offset-4 text-sm text-blue-700 dark:text-white dark:hover:text-white/90 dark:hover:text-gray-100 rounded-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 dark:focus-visible:ring-offset-gray-800 mt-5"
                        >
                            Forgotten Password
                        </a>
                    </div>

                    <div class="flex flex-col gap-6 justify-end">
                        <input-button type="submit" label="Login" class="bg-green-500 hover:bg-green-600" />

                        <div class="flex justify-center gap-3">
                            <span class="text-sm">New to Yoclo?</span>
                            <a
                                class="underline text-sm text-blue-700 dark:text-white dark:hover:text-white/90 dark:hover:text-gray-100 rounded-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 dark:focus-visible:ring-offset-gray-800"
                                href="/register">
                                Register here
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<style lang="scss">

</style>

<script>
import InputText from "./Forms/InputText.vue";
import InputLabel from "./Forms/InputLabel.vue";
import InputCheckbox from "./Forms/InputCheckbox.vue";
import InputButton from "./Forms/InputButton.vue";
import SvgIcon from "./SvgIcon.vue";

export default {
    components: {InputLabel, InputText, InputCheckbox, InputButton, SvgIcon},
    setup() {
        return {

        }
    },
    data: () => ({
        csrf: '',
        alert: {
            show: false,
            type: 'error',
            message: 'Error message',
        },
        valid: false,
        email: '',
        password: '',
        remember: false,
        loading: true
    }),
    methods: {

    },
    mounted() {
        let token = document.head.querySelector('meta[name="csrf-token"]');
        this.csrf = token.content;

        // Check if there's an error message
        if (window.ServerData.errorMessage) {
            this.alert.show = true;
            this.alert.type = 'error';
            this.alert.message = window.ServerData.errorMessage;
        } else if (window.ServerData.message) {
            // Handle non-error messages
            this.alert.show = true;
            this.alert.type = 'success';
            this.alert.message = window.ServerData.message;
        }
    }
}
</script>
