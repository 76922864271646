<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

// Define props for the component
const props = defineProps({
    label: String,  // The translation key to display on the button
    type: {
        type: String,
        default: 'button'  // Default type is 'button', can be 'submit' or 'reset'
    },
    class: String,  // Custom CSS classes
    disabled: {
        type: Boolean,
        default: false  // Button is enabled by default
    },
    variant: {
        type: String,
        default: 'primary'  // Default variant
    },
    active: {
        type: Boolean,
        default: false  // For active state styling
    }
})

// Internationalization
const {t} = useI18n()

// Compute the button classes (allow for custom class overrides)
const computedClass = computed(() => {
    return `relative whitespace-nowrap px-1 py-4 font-light nav-item ${props.class || ''} ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''} ${props.active ? ' nav-item-active font-semibold' : ''}`
})
</script>

<template>
    <button
        :type="type"
        :class="computedClass"
        :disabled="disabled"
        @click="$emit('click')"
    >
        <slot>{{ t(label) }}</slot> <!-- Translation support -->
    </button>
</template>

<style scoped>
/* Add any additional styles here */
</style>
