<script setup>
import { computed } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import SvgIcon from "@/Components/SvgIcon.vue";

const props = defineProps({
    isOpen: Boolean,
    direction: {
        type: String,
        default: 'right',
        validator: (value) => ['left', 'right', 'top', 'bottom'].includes(value),
    },
});

const emit = defineEmits(['close']);

const emitClose = () => {
    emit('close');
    props.isOpen = false;
};

const transitionClasses = computed(() => {
    switch (props.direction) {
        case 'left':
            return {
                enterFrom: '-translate-x-full',
                enterTo: 'translate-x-0',
                leaveFrom: 'translate-x-0',
                leaveTo: '-translate-x-full',
            };
        case 'right':
            return {
                enterFrom: 'translate-x-full',
                enterTo: 'translate-x-0',
                leaveFrom: 'translate-x-0',
                leaveTo: 'translate-x-full',
            };
        case 'top':
            return {
                enterFrom: '-translate-y-full',
                enterTo: 'translate-y-0',
                leaveFrom: 'translate-y-0',
                leaveTo: '-translate-y-full',
            };
        case 'bottom':
            return {
                enterFrom: 'translate-y-full',
                enterTo: 'translate-y-0',
                leaveFrom: 'translate-y-0',
                leaveTo: 'translate-y-full',
            };
        default:
            return {};
    }
});
</script>

<template>
    <TransitionRoot as="template" :show="isOpen">
        <Dialog class="relative z-50" @close="emitClose">
            <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity"></div>
            </TransitionChild>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div
                        class="pointer-events-none fixed flex max-w-full"
                        :class="{
                          'inset-y-0 left-0 md:pr-10': direction === 'left',
                          'inset-y-0 right-0 md:pl-10': direction === 'right',
                          'inset-x-0 top-0 md:pb-10': direction === 'top',
                          'inset-x-0 bottom-0 md:pt-10': direction === 'bottom',
                        }"
                    >
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            :enter-from="transitionClasses.enterFrom"
                            :enter-to="transitionClasses.enterTo"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            :leave-from="transitionClasses.leaveFrom"
                            :leave-to="transitionClasses.leaveTo"
                        >
                            <DialogPanel
                                class="relative pointer-events-auto w-screen max-w-4xl bg-white dark:bg-[#181A1B] dark:text-white shadow-xl p-4"
                            >
                                <slot></slot>
                                <button
                                    @click="emitClose"
                                    class="absolute top-5 right-5 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                                >
                                    <svg-icon name="close" />
                                </button>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
