<script setup>
import Layout from '@/Layouts/Authed.vue'
import {Head, usePage} from '@inertiajs/vue3'
import {EditPencil} from "@iconoir/vue";
import SocialCounts from "@/Components/Yoclo/SocialCounts.vue";
import Badge from '@/Components/Layout/Badge.vue'
import InputButton from "@/Components/Forms/InputButton.vue";
import InputTab from "@/Components/Forms/InputTab.vue";
import UserProfileSettings from "@/Components/Yoclo/UserProfileSettings.vue";
import {marketTagOptions} from "@/data/marketTagOptions.js";
import {ref} from "vue";
import {getMarketTagIcon} from "@/utils/helpers.js";
import SvgIcon from "@/Components/SvgIcon.vue";
import SidePanel from "@/Components/Layout/SidePanel.vue";

const page = usePage();
//const user = page.props.auth.user

const {user, youtube} = defineProps({
    user: {
        type: Object,
        required: true
    },
    youtube: {
        videos: {
            type: Array,
            required: false
        }
    }
})

console.log(user, youtube);

const isModalOpen = ref(false);

// Function to toggle modal visibility

const toggleModal = () => {
    isModalOpen.value = !isModalOpen.value;
}

const closeModal = () => {
    isModalOpen.value = false;
};

// Set up reactive state for activeTab
const activeTab = ref('overview')

// Method to set active tab
const setActiveTab = (tab) => {
    activeTab.value = tab
}

function formatDate(dateString, format = 'DD-MM-YY HH:mm:ss') {
    const date = new Date(dateString);

    const pad = (num) => num.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return format
        .replace("YY", year)
        .replace("MM", month)
        .replace("DD", day)
        .replace("HH", hours)
        .replace("mm", minutes)
        .replace("ss", seconds);
}

</script>

<template>
    <Head>
        <title>Profile - Yoclo</title>
    </Head>

    <Layout>
        <div
            v-if="user?.is_bespoke_image"
            class="absolute bg-cover bg-center bg-no-repeat h-full w-full md:w-[80%] inset-0 md:left-[20%] z-0 max-h-[60vh]"
        >
        </div>
        <div v-if="!user?.is_bespoke_image" class="relative mb-5">
            <div class="bg-cover bg-center bg-no-repeat w-full h-[526px] inset-0 z-0 max-h-[60vh]"
                 :style="{ 'background-image': `url(${user?.cover_photo})`}">

                <div class="hidden md:block absolute top-0 bottom-0 left-0 right-0 z-10"
                     style="background-image: linear-gradient(to right, black 30%, rgba(43, 47, 50, 0) 100%)"></div>
                <div class="md:hidden absolute top-0 bottom-0 left-0 right-0 z-10"
                     style="background-image: linear-gradient(to right, black 5%, rgba(43, 47, 50, 0) 100%)"></div>

                <div class="container relative z-20 text-white">
                    <div class="absolute top-7 right-2.5">
                        <input-button class="hidden md:inline" @click="toggleModal">
                            <span class="hidden md:inline">Update profile page</span>
                        </input-button>
                        <input-button class="h-10 w-10 md:hidden p-0" rounded="full" @click="toggleModal">
                            <EditPencil/>
                        </input-button>
                    </div>

                    <div class="pt-10 md:pt-20">
                        <div class="flex flex-wrap items-center">
                            <Badge icon="pin" v-if="user?.location">{{ user?.location }}</Badge>
                            <div class="spacer hidden md:inline-flex mx-3" v-if="user?.location">|</div>
                            <div class="mt-3 md:mt-0 md:inline-flex flex flex-wrap gap-2">
                                <Badge v-for="(tag, index) in user?.market_tags">
                                    <component v-if="marketTagOptions[index].icon" :is="getMarketTagIcon(tag.name)"/>
                                    {{ tag.name }}
                                </Badge>
                            </div>
                        </div>

                        <h1 class="text-4xl md:text-6xl font-semibold mt-6 mb-4"
                            v-if="!user?.professional_alias || !user?.show_alias">{{ user?.first_name }}
                            {{ user?.last_name }}</h1>
                        <h1 class="text-4xl md:text-6xl  font-semibold mt-6 mb-4"
                            v-if="user?.professional_alias && user?.show_alias">{{ user?.professional_alias }}</h1>
                        <p class="text-base mb-4 uppercase">{{ user.total_followers }} followers</p>
                        <social-counts></social-counts>
                    </div>
                </div>
            </div>
        </div>

        <div class="container pb-10 md:pb-20">
            <!-- Tabs Navigation -->
            <nav class="mb-12 flex space-x-8 lg:space-x-12" aria-label="Tabs">
                <input-tab
                    :active="activeTab === 'overview'"
                    @click="setActiveTab('overview')"
                    label="Overview"
                />
                <input-tab
                    :active="activeTab === 'latestPosts'"
                    @click="setActiveTab('latestPosts')"
                    label="Latest Posts"
                />
                <input-tab
                    :active="activeTab === 'about'"
                    @click="setActiveTab('about')"
                    label="About"
                />
            </nav>

            <!-- Content for Overview Tab -->
            <div v-if="activeTab === 'overview'">
                <div class="md:mb-20 grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-12">
                    <!--                   <div v-for="i in 3" :key="i">
                                           <div class="mb-2">
                                               <svg-icon name="youtube" />
                                               <span class="inline-block ms-2">5 hours ago</span>
                                           </div>
                                           <div class="shadow-lg rounded-4xl h-[488px] dark:bg-[#171B1F]"></div>
                                           <ul class="list-disc flex justify-between font-light mt-5">
                                               <li class="list-none">
                                                   5000 <strong class="font-medium">Likes</strong>
                                               </li>
                                               <li>
                                                   400k <strong class="font-medium">Comments</strong>
                                               </li>
                                               <li>
                                                   100k <strong class="font-medium">Views</strong>
                                               </li>
                                           </ul>
                                       </div>-->
                    <!--div v-if="youtube.videos.data.length > 0" v-for="video in youtube.videos.data" :key="video.id">
                        <div class="mb-2">
                            <svg-icon name="youtube"/>
                            <span class="inline-block ms-2">{{ formatDate(video.created_at) }}</span>
                        </div>
                        <div class="shadow-lg rounded-4xl h-[488px] dark:bg-[#171B1F]">
                            {{ video.title }}
                            <img :src="video.thumbnail"/>
                        </div>
                        <ul class="list-disc flex justify-between font-light mt-5">
                            <li class="list-none">
                                {{ video.like_count }} <strong class="font-medium">Likes</strong>
                            </li>
                            <li>
                                {{ video.comment_count }} <strong class="font-medium">Comments</strong>
                            </li>
                            <li>
                                {{ video.view_count }} <strong class="font-medium">Views</strong>
                            </li>
                        </ul>
                    </div>-->

                    <div
                        class="bg-gray-900 rounded-xl border border-gray-800 overflow-hidden hover:border-gray-700 transition-all duration-300"
                        v-if="youtube.videos.data.length > 0" v-for="video in youtube.videos.data" :key="video.id">
                        <div class="h-48 bg-gray-800 relative group">
                            <div
                                class="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            </div>
                            <img :src="video.thumbnail"/>
                        </div>
                        <div class="p-5">
                            <div class="flex items-center justify-between">
                                <div class="flex items-center space-x-2"><span
                                    class="text-sm text-gray-400">
                                    {{ video.title }} <br />
                                    {{ formatDate(video.created_at) }}
                                </span></div>
                            </div>
                            <div class="mt-4 flex items-center justify-between text-sm text-gray-400"><span
                                class="flex items-center space-x-1">
                                <svg class="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg>
                                <span>{{ video.like_count }}</span></span><span
                                class="flex items-center space-x-1"><svg class="h-4 w-4" fill="none"
                                                                         stroke="currentColor" viewBox="0 0 24 24"><path
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg><span>{{ video.comment_count }}</span></span><span
                                class="flex items-center space-x-1"><svg class="h-4 w-4" fill="none"
                                                                         stroke="currentColor" viewBox="0 0 24 24"><path
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round"
                                                                                  stroke-linejoin="round"
                                                                                  stroke-width="2"
                                                                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg><span>{{ video.view_count }}</span></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!-- Content for Latest Posts Tab -->
            <div v-if="activeTab === 'latestPosts'" class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-12">
                <div v-for="i in 6" :key="i">
                    <div class="mb-2">
                        <svg-icon name="youtube"/>
                        <span class="inline-block ms-2">5 hours ago</span>
                    </div>
                    <div class="shadow-lg rounded-3xl h-[488px] dark:bg-[#171B1F]"></div>
                    <ul class="list-disc flex justify-between font-light mt-5">
                        <li class="list-none">
                            5000 <strong class="font-medium">Likes</strong>
                        </li>
                        <li>
                            400000 <strong class="font-medium">Comments</strong>
                        </li>
                        <li>
                            100000 <strong class="font-medium">Views</strong>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Content for About Tab -->
            <div v-if="activeTab === 'about'" class="max-w-2xl">
                <strong class="mb-6 block">About me</strong>
                <p>
                    {{ user?.biography }}
                </p>
            </div>
        </div>

        <side-panel :is-open="isModalOpen" :close="toggleModal" @update:isOpen="isModalOpen = $event">
            <UserProfileSettings :user="user"/>
        </side-panel>
    </Layout>

</template>
