<script>
import {ref} from "vue";

const passwordVisibility = ref(false);
const togglePasswordVisibility = () => {
    passwordVisibility.value = !passwordVisibility.value;
};
</script>

<template>
    <div class="flex overflow-x-hidden relative registration-process text-black dark:text-white"
         style="height: calc(100vh - 95px)">
        <!-- Left Side Form -->
        <div
            class="flex flex-col items-center md:items-baseline relative min-h-full h-fit max-w-lg px-9 py-16 md:pl-20 md:pb-[128px] md:pr-[88px]  z-10 min-w-[50%] dark:bg-gray-900 shadow-[0_25px_50px_10px_#000000]">
            <a href="/">
                <logo class="mb-11 w-32"></logo>
            </a>

            <h2 class="text-3xl md:text-4xl font-medium mb-6">Create account.</h2>

            <div class="max-w-sm w-full">
                <form @submit.prevent="register">
                    <!-- Account Type Radio Buttons -->
                    <p class="mb-4">Select one that applies to you</p>
                    <div class="grid grid-cols-2 gap-2 md:gap-4 mb-8">
                        <input-radio
                            v-model="form.accountType"
                            id="creator"
                            value="creator"
                            name="account_type"
                            ariaLabelledby="creator"
                            label="I'm a creator"
                        />
                        <input-radio
                            v-model="form.accountType"
                            id="brand"
                            value="brand"
                            name="account_type"
                            ariaLabelledby="brand"
                            label="I'm a brand"
                        />
                        <input-radio
                            v-model="form.accountType"
                            id="represent_brands"
                            value="represent_brands"
                            name="account_type"
                            ariaLabelledby="represent_brands"
                            label="I represent Brands"
                        />
                        <input-radio
                            v-model="form.accountType"
                            id="represent_creators"
                            value="represent_creators"
                            name="account_type"
                            ariaLabelledby="represent_creators"
                            label="I represent Creators"
                        />
                    </div>

                    <!-- First Name -->
                    <div class="mb-6">
                        <input-label for="first_name" value="First Name" class="font-normal"/>
                        <input-text
                            v-model="form.firstName"
                            id="first_name"
                            name="first_name"
                            type="text"
                            required
                            placeholder="Enter your first name"
                            class="block mt-1 w-full"
                        />
                        <div v-if="form.errors.firstName" class="text-red-500 text-sm mt-1">{{
                                form.errors.firstName
                            }}
                        </div>
                    </div>

                    <!-- Last Name -->
                    <div class="mb-6">
                        <input-label for="last_name" value="Last Name" class="font-normal"/>
                        <input-text
                            v-model="form.lastName"
                            id="last_name"
                            name="last_name"
                            type="text"
                            required
                            placeholder="Enter your last name"
                            class="block mt-1 w-full"
                        />
                        <div v-if="form.errors.lastName" class="text-red-500 text-sm mt-1">{{
                                form.errors.lastName
                            }}
                        </div>
                    </div>

                    <!-- Email -->
                    <div class="mb-6">
                        <input-label for="email" value="Email" class="font-normal"/>
                        <input-text
                            v-model="form.email"
                            id="email"
                            name="email"
                            type="email"
                            required
                            placeholder="Enter your email"
                            class="block mt-1 w-full"
                        />
                        <div v-if="form.errors.email" class="text-red-500 text-sm mt-1">{{ form.errors.email }}</div>
                    </div>

                    <!-- Password -->
                    <div class="mb-6">
                        <input-label for="password" value="Password" class="font-normal"/>
                        <div class="relative">
                            <input-text
                                v-model="form.password"
                                id="password"
                                name="password"
                                :type="passwordVisibility ? 'text' : 'password'"
                                required
                                placeholder="Enter your password"
                                class="block mt-1 w-full"
                            />
                            <PasswordToggle :is-password-visible="passwordVisibility"
                                            :toggleFunction="togglePasswordVisibility"/>
                        </div>
                        <div v-if="form.errors.password" class="text-red-500 text-sm mt-1">{{
                                form.errors.password
                            }}
                        </div>
                    </div>

                    <!-- Terms and Conditions -->
                    <p class="text-xs mb-10">
                        By continuing to register an account, you agree to our
                        <a href="#" class="text-blue-700 dark:text-white hover:underline">Terms and Conditions</a>.
                    </p>

                    <!-- Register Button -->
                    <input-button label="Register" class="bg-green-500 hover:bg-green-600 w-full" type="submit"/>
                </form>
            </div>

            <div class="flex justify-center gap-3 mt-4">
                <span class="text-sm">Already have an account?</span>
                <a href="/login"
                   class="underline text-sm text-blue-700 dark:text-white dark:hover:text-white/90 dark:hover:text-gray-100 rounded-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 dark:focus-visible:ring-offset-gray-800">Login
                    here</a>
            </div>
        </div>

        <!-- Right Side Image -->
        <img :src="registerCardsImage"
             class="fixed top-0 right-0 creator-img hidden md:block w-full object-cover aspect-square"
             alt="A image of cards with creators"/>
    </div>
</template>

<script setup>
import {useForm} from '@inertiajs/vue3'
import InputText from "./Forms/InputText.vue";
import InputLabel from "./Forms/InputLabel.vue";
import InputButton from "./Forms/InputButton.vue";
import InputRadio from "./Forms/InputRadio.vue";
import registerCardsImage from '../../img/register-cards.png';
import Logo from "./Logo.vue";
import PasswordToggle from "@/Components/Forms/PasswordToggle.vue";

const form = useForm({
    source: 'register',
    brandName: null,
    accountType: null,
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    plan: '',
    schedule: '',
})

function register() {
    form.post('/create-account')
}
</script>

<style scoped>
/*.registration-process {
    // commenting out for now as I want the background colour to be controlled via tailwind prefixes
    //background-color: #000;
}*/

.creator-img {
    width: 50%;
    height: 100vh;
}
</style>
