<template>
  <span :class="badgeClasses">
    <!-- Conditionally show the icon if it's provided -->
    <svg-icon v-if="icon" :name="icon" />
    <slot></slot>
  </span>
</template>

<script setup>
import SvgIcon from "@/Components/SvgIcon.vue";
import {computed} from "vue";

// Props from the Blade template
const props = defineProps({
    icon: {
        type: String,
        default: '',
    },
    variant: {
        type: String,
        default: 'neutral', // Default value for variant
    },
    size: {
        type: String,
        default: 'md', // Default value for size
    },
    rounded: {
        type: String,
        default: '', // Default value for rounded (could be null)
    },
});

// Dynamically compute the classes for the badge
const badgeClasses = computed(() => {
    const baseClass = 'inline-flex text-center items-center text-sm gap-2 whitespace-nowrap';
    const variantClass = `badge-${props.variant}`;
    const sizeClass = `badge-${props.size}`;
    const roundedClass = props.rounded ? `rounded-${props.rounded}` : 'rounded';

    return `${baseClass} ${variantClass} ${sizeClass} ${roundedClass}`;
});
</script>

<style>
/* If there are any specific styles, you can add them here */
</style>
