<script setup>
// Props
const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    }
});

// Emit event to update v-model
const emit = defineEmits(['update:modelValue']);

// Handle input change
const updateValue = (event) => {
    emit('update:modelValue', event.target.value);
};
</script>

<template>
  <textarea
      :disabled="disabled"
      v-bind="$attrs"
      :value="modelValue"
      @input="updateValue"
      class="dark:bg-black/10 dark:text-gray-300 focus:border-indigo-500 dark:focus:border-indigo-600 focus:ring-indigo-500 dark:focus:ring-indigo-600 shadow-sm !rounded-[8px] px-5 border border-black dark:border-white block mt-1 py-2.5 w-full"
    ></textarea>
</template>
