<script setup>
import {ref} from 'vue'
import SvgIcon from "@/Components/SvgIcon.vue";
import {usePage} from "@inertiajs/vue3";

const page = usePage();
const {user} = page.props.auth;

// Props for platform and connection state
const props = defineProps({
    platform: {
        type: String,
        required: true
    },
    username: {
        type: String,
        default: 'AaronG'
    },
    avatar: {
        type: String,
        default: ''
    }
});

const platformId = ref(null);

console.log(props);

console.log(`${props.platform}_id`);
platformId.value = user[`${props.platform}_id`];

// Base URL for the Laravel API (assuming it's on the same server)
const baseUrl = '/oauth';

// Methods for connecting and disconnecting accounts
const connect = async () => {
    window.location.href = `${baseUrl}/${props.platform}/redirect`;
};

const disconnect = async () => {
    if (confirm(`Are you sure you want to remove this ${props.platform} account?`)) {
        try {
            const response = await axios.post(`/oauth/${props.platform}/disconnect`);

            // Handle successful disconnection
            console.log(`Disconnected from ${props.platform}`, response.data);

            // Reset platformId, username, and avatar after disconnection
            platformId.value = null;
            props.username = '';
            props.avatar = '';

            alert(`Successfully disconnected from ${props.platform}!`);
        } catch (error) {
            console.error(`Failed to disconnect from ${props.platform}:`, error);
            alert(`Failed to disconnect from ${props.platform}. Please try again.`);
        }
    }
}

const connectOrDisconnect = () => {
    if (platformId.value) {

        disconnect();
    } else {
        connect();
    }
}
</script>

<template>
    <div :class="[
        'bg-gradient-to-r p-0.5 rounded-[20px] overflow-hidden',
        platformId ? 'from-green-300 to-[#7435FF]' : 'from-[#eeeeee] to-[#eeeeee]'
        ]">
        <a href="#"
           @click.prevent="connectOrDisconnect()"
           class="relative bg-white dark:bg-[#181A1B] flex flex-col justify-between gap-6 py-4 px-5 rounded-[20px]">
            <template v-if="platformId">
                <div class="flex items-center">
                    <svg-icon name="checkmark-circle" class="mb-1 me-2" />
                    <span>{{ platform.charAt(0).toUpperCase() + platform.slice(1) }}/{{ username }}</span>
                    <span class="ms-auto border border-[#60ACFA] dark:bg-[#60ACFA] me-5 py-0.5 px-2 rounded-full text-xs">
                      Unlink
                    </span>
                    <img :src="user[platform + '_user'].avatar" alt="" class="w-8 h-8 rounded-full">
                </div>
            </template>

            <template v-else>
                <div class="flex items-center">
                    <svg-icon name="plus-circle" class="mb-1 text-gray-400 dark:text-white" />
                    <span class="ms-2">Add {{ platform.charAt(0).toUpperCase() + platform.slice(1) }} account</span>
                    <!-- Insert social icon based on platform -->
                    <div class="ms-auto">
                        <svg-icon :name="platform" classes="ms-auto"/>
                    </div>
                </div>
            </template>
        </a>
    </div>
</template>

<style scoped>
/* Add any necessary styles here */
</style>
