<script setup>

import {onMounted} from "vue";

onMounted(() => {
    if (!localStorage.getItem('currentTheme') || localStorage.getItem('currentTheme') === 'dark') {
        document.body.classList.add('dark');
    }
});

</script>


<template>
    <main class="dark:bg-gray-950 black h-full antialiased text-gray-800 dark:text-white">
        <slot />
    </main>
</template>
