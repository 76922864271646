<script setup>
import { computed } from 'vue'

// Props passed to the component
const props = defineProps({
    active: {
        type: Boolean,
        default: false
    },
    href: {
        type: String,
        required: true
    }
})

// Computed class to dynamically assign classes based on active state
const computedClass = computed(() => {
    return props.active
        ? 'relative inline-flex items-center px-1 pt-1 nav-item nav-item-active pb-3 pt-3 leading-1 text-gray-900 dark:text-white focus:outline-none focus:border-indigo-700 transition duration-150 ease-in-out font-semibold md:text-lg'
        : 'relative inline-flex items-center px-1 pt-1 nav-item pb-3 pt-3 font-light leading-1 text-gray-500 dark:text-white hover:border-gray-300 dark:hover:border-gray-700 focus:outline-none focus:text-gray-700 dark:focus:text-gray-300 focus:border-gray-300 dark:focus:border-gray-700 transition duration-150 ease-in-out md:text-lg'
})
</script>

<template>
    <a :href="href" :class="computedClass">
        <slot></slot>
    </a>
</template>

<style>
/* Optionally add scoped styles here */
</style>
