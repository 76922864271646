<script setup>

import {computed, defineProps} from 'vue';
import FooterComponent from '@/Components/Footer.vue';
import Logo from '@/Components/Logo.vue';
import registerCardsImage from '../../img/register-cards.png';

const props = defineProps({
    title: {
        type: 'string',
        value: '',
    },
    width: {
        type: 'string',
        value: 'xs'
    }
})

const classes = computed(() => {
    `max-w-${props.width} w-full`
});

</script>

<template>

    <div class="text-white">
        <div class="flex overflow-x-hidden registration-process relative items-center" style="height: calc(100vh - 95px)">
            <div class="flex flex-col items-center md:items-baseline relative min-h-full h-fit md:max-w-lg px-9 py-16 md:pl-20 md:pb-[128px] md:pr-[88px] z-10 min-w-[50%] dark:bg-gray-900 shadow-[0_25px_50px_10px_#000000]">
                <a href="login">
                    <Logo class="mb-11 w-32"/>
                </a>

                <h2 class="text-3xl md:text-4xl font-medium mb-6">{{ props.title }}.</h2>
                <div :class="classes">
                    <slot></slot>
                </div>
            </div>
            <img :src="registerCardsImage" class="fixed top-0 right-0 creator-img hidden md:block w-full object-cover aspect-square"
                 alt="A image of cards with creators"/>
        </div>
        <footer-component></footer-component>
    </div>
</template>
