<template>
    <!-- TODO - Change later so it only returns the svg -->
<!--    <span v-html="svgContent" :class="[!customClass ? 'text-black dark:text-white' : customClass]" />-->
    <span v-html="svgContent" :class="['text-black dark:text-white', customClass]" />
</template>

<script setup>
import {ref, watchEffect} from 'vue'

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    class: {
        type: String,
        default: '',
    },
})

const svgContent = ref(null)
const customClass = props.class

// Dynamically load the SVG based on the name prop
watchEffect(async () => {
    if (props.name) {
        try {
            // Fetch the SVG from the public/svg directory
            const svg = await fetch(`/build/svg/${props.name}.svg`)
            svgContent.value = await svg.text()
        } catch (error) {
            console.error('Error loading SVG:', error)
        }
    }
})
</script>

<style scoped>
/* Add any scoped styles here if needed */
</style>
