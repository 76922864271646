<script setup>
import { useForm, usePage } from '@inertiajs/vue3'

const form = useForm({
    email: null,
})

const { props } = usePage()

function sendResetLink() {
    form.post('/password/email', {
        preserveScroll: true,
    })
}
</script>

<template>
    <div>
        <div class="relative flex flex-col min-h-screen">
            <div
                class="container my-auto px-9 md:pt-14 md:px-10 md:pb-[72px] max-w-[485px] md:bg-gray-900 flex flex-col !text-white sm:rounded-lg relative z-10 md:shadow-2xl">

                <a href="/" class="inline-block mb-9 -ms-1">
                    <svg-icon name="arrow-left"></svg-icon>
                </a>

                <h2 class="text-3xl font-medium mb-6">Reset Your Password</h2>

                <!-- Display success message -->
                <div v-if="form.success" class="text-green-500 text-sm mt-1 mb-3">
                    {{ form.success }}
                </div>

                <!-- Display general error message -->
                <div v-if="form.errors.error" class="text-red-500 text-sm mt-1 mb-3">
                    {{ form.errors.error }}
                </div>

                <form @submit.prevent="sendResetLink">
                    <div class="mb-6">
                        <input-label for="email" value="Email" class="!text-white font-normal"/>
                        <input-text
                            v-model="form.email"
                            id="email"
                            type="email"
                            name="email"
                            required
                            autofocus
                            autocomplete="username"
                            placeholder="Enter in your email"
                            class="!rounded-[8px] px-5 text-white block mt-1 py-2.5 w-full"
                        />
                        <!-- Error for Email -->
                        <div v-if="form.errors.email" class="text-red-500 text-sm mt-1">
                            {{ form.errors.email }}
                        </div>
                    </div>

                    <div class="flex flex-col gap-6 justify-end">
                        <input-button type="submit" label="Send Password Reset Link"
                                      class="bg-green-500 hover:bg-green-600"/>

                        <div class="flex justify-center gap-3">
                            <span class="text-sm">Remember your password?</span>
                            <a
                                class="underline text-sm text-white hover:text-white/90 dark:hover:text-gray-100 rounded-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 dark:focus-visible:ring-offset-gray-800"
                                href="/">
                                Log in here
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
</style>

<script>
import InputText from "./Forms/InputText.vue";
import InputLabel from "./Forms/InputLabel.vue";
import InputButton from "./Forms/InputButton.vue";
import SvgIcon from "./SvgIcon.vue";

export default {
    components: {InputLabel, InputText, InputButton, SvgIcon},
    setup() {
        return {}
    },
    data: () => ({
        csrf: '',
        alert: {
            show: false,
            type: 'error',
            message: 'Error message',
        },
        loading: true
    }),
    mounted() {
        let token = document.head.querySelector('meta[name="csrf-token"]');
        this.csrf = token.content;

        // Check if there's an error message
        if (window.ServerData.errorMessage) {
            this.alert.show = true;
            this.alert.type = 'error';
            this.alert.message = window.ServerData.errorMessage;
        } else if (window.ServerData.message) {
            // Handle non-error messages
            this.alert.show = true;
            this.alert.type = 'success';
            this.alert.message = window.ServerData.message;
        }
    }
}
</script>
