<script>
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue';
import SvgIcon from "@/Components/SvgIcon.vue";
export default {
    components: {SvgIcon, Dialog, DialogPanel, TransitionRoot, TransitionChild },
    emits: ['update:isOpen'],
    props: {
        isOpen: false,
    },
    methods: {
        emitClose() {
            this.$emit('update:isOpen', false);
        },
    },
};
</script>


<template>
    <TransitionRoot as="template" :show="isOpen">
        <Dialog class="relative z-50" @close="emitClose">
            <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity"></div>
            </TransitionChild>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10">
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700 translate-x-px transform-gpu"
                            enter-from="-translate-x-full"
                            enter-to="translate-x-px"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-px"
                            leave-to="-translate-x-full"
                        >
                            <DialogPanel class="relative pointer-events-auto w-screen max-w-4xl bg-white dark:bg-[#181A1B] dark:text-white shadow-xl p-4">
                                <slot></slot>
                                <button
                                    @click="emitClose"
                                    class="absolute top-5 right-5 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                                >
                                    <svg-icon name="close" />
                                </button>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>
