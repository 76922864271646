<script setup>
import { usePage, useForm } from '@inertiajs/vue3'

import Layout from "@/Layouts/Default.vue";
import SplitForm from "@/Layouts/SplitForm.vue";
import InputLabel from "@/Components/Forms/InputLabel.vue";
import InputText from "@/Components/Forms/InputText.vue";
import InputButton from "@/Components/Forms/InputButton.vue";
import PasswordToggle from "@/Components/Forms/PasswordToggle.vue";
import {ref} from "vue";

const form = useForm({
    password: null,
    passwordConfirmation: null,
})

// Access the props, including the email
const { props } = usePage()
const email = props.user.email;
const resetToken = props.resetToken;

function changePassword() {
    form.post('/change-password/' + resetToken, form)
}

const passwordVisibility = ref(false);
const togglePasswordVisibility = () => {
    passwordVisibility.value = !passwordVisibility.value;
};

const passwordConfirmationVisibility = ref(false);
const togglePasswordConfirmationVisibility = () => {
    passwordConfirmationVisibility.value = !passwordConfirmationVisibility.value;
};

</script>

<template>

    <Layout>
        <SplitForm title="Reset your password">
            <p class="text-white mb-4">Please enter your new password below. Make sure it’s strong and secure.</p>

            <!-- Display success message if available -->
            <div v-if="props.flash.success" class="text-green-500">
                {{ props.flash.success }}
            </div>

            <!-- Display general error message -->
            <div v-if="form.errors.error" class="text-red-500 text-sm mt-1 mb-3">
                {{ form.errors.error }}
            </div>

            <form @submit.prevent="changePassword">
                <!-- Email Address -->
                <div>
                    <InputLabel for="email" value="Email"/>
                    <InputText
                        id="email"
                        class="block mt-1 w-full"
                        type="email"
                        name="email"
                        :value="email"
                        required
                        autofocus
                        disabled="disabled"
                        autocomplete="username"
                    />
                </div>

                <!-- Password -->
                <div class="mt-4">
                    <InputLabel for="password" value="Password"/>
                    <div class="relative">
                        <InputText
                            id="password"
                            v-model="form.password"
                            class="block mt-1 w-full"
                            :type="passwordVisibility ? 'text' : 'password'"
                            name="password"
                            required
                            autocomplete="new-password"
                        />
                        <PasswordToggle :is-password-visible="passwordVisibility" :toggleFunction="togglePasswordVisibility" />
                    </div>
                </div>

                <!-- Confirm Password -->
                <div class="mt-4 mb-10">
                    <InputLabel for="password_confirmation" value="Confirm you password"/>

                    <div class="relative">
                        <InputText
                            id="password_confirmation"
                            v-model="form.passwordConfirmation"
                            class="block mt-1 w-full"
                            :type="passwordConfirmationVisibility ? 'text' : 'password'"
                            name="password_confirmation"
                            required
                            autocomplete="new-password"
                        />
                        <PasswordToggle :is-password-visible="passwordConfirmationVisibility" :toggleFunction="togglePasswordConfirmationVisibility" />
                    </div>

                </div>

                <input-button type="submit">Reset password</input-button>
            </form>

        </SplitForm>
    </Layout>

</template>
