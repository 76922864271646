<script setup>
import { onMounted } from 'vue';
import Navigation from "@/Components/Navigation.vue";
import FooterComponent from "@/Components/Footer.vue";

onMounted(() => {
    document.body.classList.add(localStorage.getItem('currentTheme'));
})

</script>

<template>
    <main class="dark:bg-gray-950 h-full antialiased text-gray-800 dark:text-white">
        <navigation />
        <div class="pt-[81px] md:pt-[100px]">
            <slot />
        </div>
    </main>
    <footer-component></footer-component>
</template>
