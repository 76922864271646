<script setup>
import { ref } from 'vue';
import { Head } from '@inertiajs/vue3';
import Layout from '../Layouts/Default.vue';
import ChoosePlanCard from "@/Components/Yoclo/ChoosePlanCard.vue";
import SubHeader from "@/Components/SubHeader.vue";
import Logo from "@/Components/Logo.vue";
import InputButton from "@/Components/Forms/InputButton.vue";

// Define refs for the selected plan and plan type (monthly/yearly)
const selectedPlan = ref(null);
const planType = ref('monthly'); // Default is 'monthly'

// Function to select a plan
const selectPlan = (plan) => {
    selectedPlan.value = plan;
    console.log(`Selected Plan: ${plan}`);
};

// Function to toggle between Monthly and Yearly plans
const togglePlanType = (type) => {
    planType.value = type;
};
</script>

<template>
    <Head>
        <title>Choose Your Plan - Yoclo</title>
        <meta name="description" content="Select the right plan for your needs on Yoclo." />
    </Head>

    <Layout>
        <div class="h-screen pt-16">
            <div class="container pb-20 overflow-y-auto" style="height: calc(100% - 94px)">
                <a href="/login">
                    <logo class="mb-10"/>
                </a>
                <sub-header title="Choose a plan that's right for you" />

                <!-- Step Information -->
                <p class="text-2.5xl font-medium">Step 1 of 3</p>
                <h2 class="text-2.5xl mb-16">Choose a plan that's right for you</h2>

                <!-- Monthly and Yearly Buttons -->
                <div class="text-center mb-20">
                    <InputButton class="me-2 w-40" :variant="planType === 'monthly' ? 'primary' : 'secondary'" @click="togglePlanType('monthly')">
                        Monthly
                    </InputButton>
                    <InputButton class="me-2 w-40" :variant="planType === 'yearly' ? 'primary' : 'secondary'" @click="togglePlanType('yearly')">
                        Yearly
                    </InputButton>
                </div>

                <!-- Plan Cards -->
                <div class="grid grid-cols-3 gap-6">
                    <!-- Basic Plan -->
                    <choose-plan-card
                        plan="Basic"
                        :planType="planType"
                        :value="planType === 'monthly' ? '500' : '5,000'"
                        :items="['1 licence', 'AI search functionality', 'Brand Dashboard', 'Direct messenger']"
                    >
                        <input-button :href="'/choose-plan/' + planType + '/basic'">
                            Select plan
                        </input-button>
                    </choose-plan-card>

                    <!-- Business Plan with Badge -->
                    <choose-plan-card
                        hasBadge
                        plan="Business"
                        :planType="planType"
                        :value="planType === 'monthly' ? '800' : '8,000'"
                        :items="[
                          '3 licences',
                          'Dedicated customer service',
                          'AI search functionality',
                          'Brand dashboard',
                          'Direct message',
                          'Analytics'
                        ]"
                    >
                        <input-button :href="'/choose-plan/' + planType + '/business'">
                            Select plan
                        </input-button>
                    </choose-plan-card>

                    <!-- Enterprise Plan -->
                    <choose-plan-card
                        plan="Enterprise"
                        :planType="planType"
                        :value="planType === 'monthly' ? '1,500' : '15,000'"
                        :items="[
                          '3 licences',
                          'Dedicated customer service',
                          'AI Search functionality',
                          'Brand dashboard',
                          'Direct messenger',
                          'Analytics',
                          'Dedicated support'
                        ]"
                    >
                        <input-button :href="'/choose-plan/' + planType + '/enterprise'">
                            Select plan
                        </input-button>
                    </choose-plan-card>
                </div>
            </div>
        </div>
    </Layout>
</template>
