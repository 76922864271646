<script setup>
import Authed from "@/Layouts/Authed.vue";
import Button from '@/Components/Forms/InputButton.vue';
import {useForm, usePage} from "@inertiajs/vue3";
import InputTab from "@/Components/Forms/InputTab.vue";
import {ref} from "vue";
import SvgIcon from "@/Components/SvgIcon.vue";
import {LogOut, Settings, User} from "@iconoir/vue";
import DropDownLink from "@/Components/Layout/DropDownLink.vue";
import DropDown from "@/Components/Layout/DropDown.vue";
import SidePanel from "@/Components/Layout/SidePanel.vue";

const user = usePage().props.auth.user;

const props = defineProps({
    users: Object,
    query: String
});

const activeTab = ref('creators');
const setActiveTab = (tab) => {
    activeTab.value = tab;
}

const form = useForm({
    account_type: '',
    name: '',
    email: '',
});
// Function to handle form submission
const submit = () => {
    form.post('/admin/users');
};

const isUserAddPanelOpen = ref(false);

const toggleUserAddPanel = () => {
    isUserAddPanelOpen.value = !isUserAddPanelOpen.value;
};

</script>

<template>

    <Authed>

        <div class="container">
            <h1 class="text-6xl mb-2">Hi, {{ user.first_name }}</h1>
            <!-- Replace with agency name -->
            <p class="mb-8">Part of world 84</p>

            <p class="mb-4">Key metrics</p>


            <div class="grid grid-cols-4 gap-3 h-[200px] mb-20">
                <div class="card flex flex-col justify-end shadow-lg dark:bg-gray-800 rounded-[8px] p-4">
                    <span>Total number of creators</span>
                    <strong class="text-3xl mb-4">58</strong>
                    <Button>View all Creators</Button>
                </div>
                <div class="card flex flex-col justify-center dark:bg-gray-800 shadow-lg rounded-[8px] p-4">
                    <span>Total creator following</span>
                    <strong class="text-3xl">10,508, 974</strong>
                </div>

                <div class="card flex flex-col  justify-center dark:bg-gray-800 shadow-lg rounded-[8px] p-4">
                    <span>Outstanding actions</span>
                    <strong class="text-3xl">8</strong>
                </div>
            </div>


            <nav class="-mb-px flex space-x-8 mb-12" aria-label="Tabs">
                <input-tab
                    :active="activeTab === 'creators'"
                    @click="setActiveTab('creators')"
                    label="Your creators"
                />
                <input-tab
                    :active="activeTab === 'team'"
                    @click="setActiveTab('team')"
                    label="Your team"
                />
            </nav>


            <section class="py-8">
                <div class="flex flex-row mb-10">
                    <div class="flex flex-col me-4">
                        <label for="" class="mb-2">Location</label>
                        <select name="" id="" class="form-select cursor-pointer border rounded-[8px] border-white bg-transparent text-white w-[180px]">
                            <option value="">Select</option>
                        </select>
                    </div>
                    <div class="mt-auto ms-auto">
                        <Button icon="plus" @click="toggleUserAddPanel">
                            Add user
                        </Button>
                    </div>
                </div>

                <div role="tabpanel" v-if="activeTab === 'creators'">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col" class="pe-3 py-3.5 text-left text-sm font-semibold">Username</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Email</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Socials</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Status</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold"></th>
                        </tr>
                        </thead>

                    <tbody>
                        <tr>
                            <td class="pe-3 py-3.5">
                                {{ user.name }}
                            </td>
                            <td class="px-3 py-3.5">
                                {{ user.email }}
                            </td>
                            <td class="px-3 py-3.5">
                                <svg-icon :class="['w-5 h-5 me-1', !user.youtube_id ? 'opacity-40' : undefined]" name="youtube" />
                                <svg-icon :class="['w-5 h-5 me-1', !user.twitter_id ? 'opacity-40' : undefined]" name="x" />
                                <svg-icon :class="['w-5 h-5 me-1', !user.facebook_id ? 'opacity-40' : undefined]" name="facebook"  />
                                <svg-icon :class="['w-5 h-5 me-1', !user.instagram_id ? 'opacity-40' : undefined]" name="instagram" />
                                <svg-icon :class="['w-5 h-5', !user.tiktok_id ? 'opacity-40' : undefined]" name="tiktok" />

                            </td>
                            <td class="px-3 py-3.5">{{ user.status }}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>

                <div role="tabpanel" v-if="activeTab === 'team'">
                    Content that is related to your team
                </div>
            </section>
        </div>

        <side-panel direction="left" :is-open="isUserAddPanelOpen" class="w-[300px]">
            Test this
        </side-panel>

    </Authed>


</template>
