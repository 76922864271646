<script setup>
import {defineProps } from 'vue';
import { Heart, HeartSolid } from "@iconoir/vue";
import Button from '@/Components/Forms/InputButton.vue';
import axios from 'axios';

const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        default: 'creator',
    },
});


// Toggle favorite status and send request to server
const toggleFavourite = async (user) => {
    try {
        user.is_favourited = !user.is_favourited; // Toggle the value

        await axios.post('/api/favourite', { user_id: user.id });
    } catch (error) {
        console.error('Failed to update favourite status:', error);
        user.is_favourited.value = !user.is_favourited.value; // Revert the toggle if request fails
    }
};
</script>

<template>
    <div
        class="rounded-[50px] shadow-[0px_39px_44px_-16px_rgba(0,0,0,0.25)] py-10 px-8 relative min-h-[28rem] overflow-hidden"
    >
        <a class="flex flex-col justify-end relative text-white h-full z-20">
            <button
                @click="toggleFavourite(user)"
                class="favourite-button text-red-500 absolute top-0 right-0"
            >
                <Heart v-if="!user.is_favourited" />
                <HeartSolid v-if="user.is_favourited" />
            </button>
            <h3 class="text-base text-white font-normal inline-flex items-center gap-3">
                {{ user.location }}
            </h3>
            <h2 class="text-3xl text-white font-light leading-tight">
                {{ user.first_name }} {{ user.last_name }}
            </h2>
            <p class="text-lg font-normal">
                {{ user.formatted_total_followers }} Followers
            </p>
            <div class="grid grid-cols-2 gap-3 justify-between mt-5">
                <Button :href="`/${user.account_type}s/${user.username}`">View</Button>
            </div>
        </a>

        <!-- Background Image -->
        <div
            class="absolute inset-0 w-full h-full pointer-events-none bg-center bg-cover z-0"
            :style="{ backgroundImage: 'url(' + user.profile_picture + '?image=' +  user.id +')' }"
        />

        <!-- Overlay Gradient -->
        <div
            class="absolute inset-0 w-full h-full pointer-events-none bg-gradient-to-t to-[65%] from-[#000] to-transparent z-10"
        ></div>
    </div>
</template>
