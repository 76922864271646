<template>
    <input
        :id="id"
        :type="type"
        :name="name"
        :class="computedClass"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    />
</template>

<script setup>
import { computed } from 'vue'

// Define props for the component
const props = defineProps({
    modelValue: String,  // v-model binds to this value
    id: String,
    type: {
        type: String,
        default: 'text'
    },
    name: String,
    class: String
})

// Compute class with custom overrides
const computedClass = computed(() => {
    return `outline-none dark:border-[#ADB2BD] text-black dark:text-white dark:bg-transparent !rounded-[8px] px-5 block mt-1 py-2.5 w-full border border-black dark:border-grey-100 focus:ring focus:ring-focus-blue ${props.class || ''}`
})
</script>

<style>

</style>
